@use "sass:math";
@use "uswds-core" as *;

// Alert variables ---------- //
$site-alert-icons: (
  info: "info",
  emergency: "error",
);

.usa-site-alert {
  .usa-alert {
    @include alert-styles;
  }
}

@each $name, $icon in $site-alert-icons {
  .usa-site-alert--#{$name} .usa-alert {
    @include alert-status-wrapper-styles($name);

    .usa-alert__body {
      @include alert-status-body-styles($name, $icon);
    }
  }
}

.usa-site-alert--slim .usa-alert {
  @include alert-styles-slim;
}

.usa-site-alert--no-icon .usa-alert {
  @include alert-styles-no-icon;
}
