@use "uswds-core" as *;

.usa-legend {
  @include typeset(
    $theme-form-font-family,
    $theme-body-font-size,
    $theme-input-line-height
  );
  display: block;
  font-weight: font-weight("normal");
  margin-top: units(3);
  max-width: units($theme-input-max-width);
}

.usa-legend--large {
  font-size: font-size($theme-form-font-family, "xl");
  font-weight: font-weight("bold");
  margin-top: units(2);
}
