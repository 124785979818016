@use "sass:meta";
@use "sass:list";
@use "../general/focus-outline" as *;
@use "../../functions" as *;
@use "../../mixins/utilities" as *;
@use "../../mixins/general/focus-outline" as *;
@use "../../settings" as *;

/*
----------------------------------------
typeset()
----------------------------------------
Sets:
- family
- size
- line-height
----------------------------------------
*/

@mixin typeset(
  $family: $theme-body-font-family,
  $scale: $theme-body-font-size,
  $line-height: $theme-body-line-height
) {
  @if meta.type-of($family) == "list" {
    $list: $family;
    $family: if(list.nth($list, 1), list.nth($list, 1), null);
    $scale: if(list.nth($list, 2), list.nth($list, 2), null);
    $line-height: if(list.nth($list, 3), list.nth($list, 3), null);
  }
  $family: if($family == null, $theme-body-font-family, $family);
  $scale: if($scale == null, $theme-body-font-size, $scale);
  $line-height: if($line-height == null, $theme-body-line-height, $line-height);
  @include u-font($family, $scale);
  @include u-line-height($family, $line-height);
}

@mixin typeset-heading {
  @include u-margin-y(0);
  clear: both;

  * + & {
    margin-top: 1.5em; // TODO: add as var to settings?
  }

  + * {
    margin-top: 1em;
  }
}

// typeset element mixins
@mixin typeset-p {
  line-height: line-height($theme-body-font-family, $theme-body-line-height);
  margin-bottom: 0;
  margin-top: 0;
  max-width: measure($theme-text-measure);

  * + & {
    margin-top: 1em; // TODO: add as var to settings?
  }

  + * {
    margin-top: 1em;
  }
}

@mixin typeset-link {
  color: color($theme-link-color);
  text-decoration: underline;

  &:visited {
    color: color($theme-link-visited-color);
  }

  &:hover {
    color: color($theme-link-hover-color);
  }

  &:active {
    color: color($theme-link-active-color);
  }

  &:focus {
    @include focus-outline;
  }
}
