@use "uswds-core" as *;

.usa-label {
  @include typeset(
    $theme-form-font-family,
    $theme-body-font-size,
    $theme-input-line-height
  );
  display: block;
  font-weight: font-weight("normal");
  margin-top: units(3);
  max-width: units($theme-input-max-width);
}

.usa-label--error {
  font-weight: font-weight("bold");
  margin-top: 0;
}

.usa-label--required {
  color: color("error-dark");
}
