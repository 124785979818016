@use "uswds-core" as *;

.usa-textarea {
  @extend %block-input-general;
  @extend %block-input-styles;
}

.usa-textarea {
  height: units("card");
}
